
import React, { forwardRef }  from 'react'

const Logo = ({ height, width ,logo,opacity, ...props }) => {
    
    return (
        <>
        <svg id="logo"  width="48" height="15" viewBox="0 0 48 15" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ height: `${height}px`, width: `${width}px`,opacity:opacity }} ref={logo} {...props} >
            <g clip-path="url(#clip0)" fill="white">
                <path d="M10.8147 3.75761V14.9241H10.1072L1.94167 2.61136V11.7814C1.94167 13.4818 2.40828 14.294 3.40922 14.7647V14.8026H0V14.7647C0.903104 14.294 1.35466 13.4818 1.35466 12.0167V3.36287C1.35466 2.05721 1.19661 1.50306 0.338664 1.00964V0.97168H4.41016L10.2277 9.76216V3.99293C10.2277 2.27735 9.76105 1.48028 8.76011 1.00964V0.97168H12.1693V1.00964C11.2662 1.48028 10.8147 2.29253 10.8147 3.75761Z"  />
                <path d="M12.1692 9.93653C12.1692 6.95323 14.1861 4.88086 16.775 4.88086C19.3413 4.88086 21.3583 6.95323 21.3583 9.93653C21.3583 12.9426 19.3413 14.9922 16.775 14.9922C14.1861 14.9998 12.1692 12.9426 12.1692 9.93653ZM18.4759 9.93653C18.4759 7.05191 17.9867 5.25282 16.775 5.25282C15.5634 5.25282 15.0516 7.05191 15.0516 9.93653C15.0516 12.7984 15.5634 14.6354 16.775 14.6354C17.9867 14.643 18.4759 12.806 18.4759 9.93653Z"  />
                <path d="M27.7707 7.49264C27.7707 6.90053 27.417 6.52098 26.7547 6.52098C25.8365 6.52098 25.0087 7.72796 25.0087 9.54223V12.7457C25.0087 14.0286 25.3775 14.3474 26.2655 14.7649V14.8029H21.4565V14.7649C22.2241 14.3094 22.322 14.0134 22.322 12.7077V7.2725C22.322 5.95165 22.1489 5.57209 21.3586 5.11663V5.07867H25.0012V7.94051C25.3925 6.32361 26.2128 4.89648 27.6879 4.89648C28.7867 4.89648 29.5317 5.648 29.5317 6.69557C29.5317 7.91774 28.749 8.59335 27.8685 8.59335C27.5374 8.59335 27.2815 8.49466 27.1837 8.3732C27.5148 8.25934 27.7707 7.92533 27.7707 7.49264Z"  />
                <path d="M35.9511 11.5383C35.778 13.4968 35.0103 14.9998 33.1138 14.9998C31.5259 14.9998 30.4873 14.0282 30.5099 11.9179L30.5324 5.59447H29.5315V5.3971C31.2173 5.00237 32.5117 3.65874 33.016 1.68506H33.1891V5.08587H35.7178L35.6199 5.60206H33.1891V11.9634C33.1891 13.0869 33.5428 13.6258 34.2502 13.6258C35.0329 13.6258 35.5597 12.8971 35.778 11.5079L35.9511 11.5383Z"  />
                <path d="M39.6384 0V7.43168C40.0899 5.81478 41.1661 4.88107 42.6186 4.88107C44.3646 4.88107 45.3053 6.06528 45.3053 7.94028V12.6999C45.3053 14.0056 45.4257 14.3016 46.1633 14.7571V14.795H41.7531V14.7571C42.5208 14.3016 42.6186 14.0056 42.6186 12.6999V7.94028C42.6186 7.02935 42.2649 6.27783 41.3242 6.27783C40.3834 6.27783 39.6384 7.16599 39.6384 8.94231V12.6999C39.6384 14.0056 39.7588 14.3016 40.4963 14.7571V14.795H36.0861V14.7571C36.8538 14.3016 36.9516 14.0056 36.9516 12.6999V2.17105C36.9516 0.865384 36.7334 0.470648 35.9507 0.0379555V0H39.6384Z"  />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="48" height="15" fill="white" />
                </clipPath>
            </defs>
        </svg>
        </>
    )
}

export default forwardRef(Logo)
