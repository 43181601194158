import React, { memo } from 'react'

const Section = memo(({ className, name, text, image, textClassName, ...props }) => {
    return (<div className={"flex flex-r p-l-20 p-r-20 p-t-40 p-b-40 h-100vh w-100vw re column m-h-a  m-p-0-v  m-p-0 " + className} {...props}>
        <div className={("w-49p h-100p p-l-20 p-r-20 empty-d-n m-h-100vh z-2 " + (name === 'MANAGING DIRECTOR' ? 'm-p-80' : 'm-p-0'))}>
            {image}
        </div>
        <div className={"h-100vh w-1px back-grey m-m-a m-ab z-1 m-h-100p " }></div>
        <div className={"w-49p h-100p p-l-20 p-r-20 flex flex-c m-h-100vh m-p-40-v m-p-l-20 m-p-r-20 m-flex-e m-p-b-60 " + textClassName}>
            {text}
        </div>
    </div>)
}, () => true)

export default Section