import React from 'react'

const External = ({className}) => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M13.1327 12.2076L13.1474 12.1929L13.1473 12.1722L13.1359 5.92043L13.1358 5.87072L13.0861 5.87052L6.83435 5.84608L6.81352 5.84599L6.79879 5.86072L6.06272 6.5968L5.97771 6.68181L6.09793 6.68215L11.6873 6.69817L5.61007 12.7754L5.57472 12.8107L5.61007 12.8461L6.15384 13.3898L6.18919 13.4252L6.22455 13.3898L12.3081 7.30627L12.3178 12.9019L12.318 13.0223L12.4031 12.9372L13.1327 12.2076Z" fill="white" stroke="white" stroke-width="0.1" />
            <circle cx="9.5" cy="9.5" r="9" stroke="white" />
        </svg>
    )
}

export default External