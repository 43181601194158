import React, { useEffect, useRef, useState,forwardRef,memo, useLayoutEffect} from 'react'
import Logo from '../svgs/logo'
import { gsap,Power1 } from "gsap";
import { mobile } from './utlis/mobile';


const Menu = ({scrollTo,setLoaded,loaded,first,second,contact,logo,logoMenu,lineMenu}) =>{
    const [isMobile,setMobile] = useState(null)
    useLayoutEffect(() =>{
        if(mobile()){
            setMobile(false)
        }else{
            setMobile(true)
        }
    },[])
    return(
        <>
        <div className="flex flex-r ju-sb h-95 fi w-100vw z-5 p-l-40 p-r-40">
            <div className={("w-33p flex flex-c ju-c "  + (!loaded ? 'd-n' :'')) } ref={first}>
                <Logo logo={logoMenu} opacity ={0} className="m-d-u"  /> 
            </div>
            <div  className={(" flex ju-c al-i-c flex-c  z-5 re " + (!loaded ? "w-100p h-100vh back-black" :"w-33p"))} ref={second}>
               
                <Logo logo={logo}  className=" logo" />
            </div>
          
            <div  className={("al-i-c flex flex-r ju-e w-33p op0 " + ( !loaded ? 'd-n' :'')) } ref={contact}>
                <div >
                    <button className="contact" onClick={() =>{scrollTo('last')}}>
                    <span className="h4 upper">Contact Us</span>
                    </button>
                </div>
            </div>
        </div>
        <div className="d-n h-95  fi flex flex-r ju-c al-i-c re z-0">
        <div className=" w-1px back-grey m-m-a m-ab  z-1 m-h-100p d-n " style={{opacity:0}} ref={lineMenu}></div>
        </div>
        </>
    )

}

export default memo(forwardRef(Menu))