import React from "react";
import External from "../svgs/external";
const TextSection = ({ sectionName, sectionAbout, sectionCopy, sectionLink, maxWidth }) => {

    return (
        <div className="flex flex-c h-100p m-ju-sb m-m-h-u" style={{ maxWidth: maxWidth }} >

            <div className={sectionLink ? " " : ""}>
                <h2>{sectionName}</h2>
            </div>
            <div className={sectionLink ? " m-flex m-flex-c" : ""}>
                <div>
                    <h1>{sectionAbout}</h1>
                </div>
                <div>
                    {sectionCopy?.split('/n').map((copy, index) => {
                        return (
                            <>
                                <p>{copy}</p> {(index === sectionCopy.length - 1) ? null : <br />}
                            </>
                        )
                    })}
                </div>
                {sectionLink ?
                    <div className="flex-grow-1 flex flex-c ju-c flex ">
                        <div className="p-t-20 p-b-20">
                            <a href={sectionLink.link}  target="_blank" rel="noreferrer noopener" className='flex-r al-i-c flex external-link '>
                               <External className="m-r-10 "/> <h4>{sectionLink.name}</h4>
                            </a>
                        </div>
                    </div>
                    : null}

            </div>
        </div>
    )
}

export default TextSection
