import React from 'react'

const Arrow = () => {
    return (
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.103 9.75359L11.1176 9.73891L11.1176 9.71814L11.1066 3.68165L11.1065 3.63194L11.0568 3.63174L5.02028 3.60814L4.99946 3.60805L4.98473 3.62278L4.274 4.33351L4.18899 4.41852L4.30921 4.41887L9.70196 4.43432L3.83694 10.2993L3.80158 10.3347L3.83694 10.3701L4.36198 10.8951L4.39734 10.9304L4.43269 10.8951L10.3039 5.02392L10.3132 10.4228L10.3134 10.5432L10.3985 10.458L11.103 9.75359Z" fill="white" stroke="white" stroke-width="0.1" />
        </svg>

    )
}

export default Arrow