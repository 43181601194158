import { useState,useLayoutEffect } from "react"
import isMobile from "./mobile"

const useMobile = () : boolean =>{
    const [mobile,setMobile] = useState(false)
    useLayoutEffect(() =>{
        if(isMobile(768,768)){
            setMobile(true)
        }else{
            setMobile(false)
        }
    },[isMobile])

    return mobile
}

export default useMobile