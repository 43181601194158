import React ,{memo} from 'react'
import Logo from '../svgs/logo'
import Arrow from '../svgs/arrow'
import ArrowUp from '../svgs/arrowUp'

const Footer = ({scrollTo}) => {
    return (
        <div className="h-100vh flex flex-c ju-sb al-i-c m-p-b-60">
            <div className="flex-grow-1 m-h-160 w-100p al-i-c flex flex-c m-h-100">
                <div className="w-1px h-100p back-grey"></div>
            </div>
           
            <div className=" flex flex-c m-w-950 ju-sb w-100vw al-i-c flex-grow-1 m-h-240 m-h-400 m-flex-grow-u ">

                <Logo height="40" width="120" className="m-m-40 m-d-n" />
                <div className="w-100p flex flex-r w-100p m-flex-c m-al-i-c m-flex-grow-1 m-ju-c">
                    <div className="w-33p al-i-c flex flex-c m-m-b-10">
                        <h4 className="upper m-b-20 center m-m-b-20 m-m-b-10">Contact</h4>
                        <a className="p white" href="mailto:scott@northdevelopment.com.au">scott@northdevelopment.com.au</a>
                    </div>
                    <div className="w-33p al-i-c flex flex-c m-m-b-10">
                        <h4 className="upper m-b-20 center m-m-b-20 m-m-b-10">Address</h4>
                        <a className="p center white">Level 2 / 17 Byres Street <br />NEWSTEAD QLD 4006</a>
                    </div>
                    <div className="w-33p al-i-c flex flex-c">
                        <h4 className="upper m-b-20 center m-m-b-20 m-m-b-10">Social</h4>
                        <div className="flex flex-c ju-c al-i-c ">
                            <a className="p white " href="https://www.instagram.com/north_developments/">Instagram<Arrow /></a>
                            <a className="p white" href="https://www.linkedin.com/in/scott-ginnivan-0b627516/">Linkedin<Arrow /></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-grow-1 m-h-160 ju-e flex-grow-1 w-100p p-l-40 p-r-40 p-b-40 flex flex-c ">
                <div className="flex flex-r w-100p m-flex-c m-al-i-c">
                    <div className="w-33p m-ju-c flex flex-r m-m-b-20 m-m-b-5">
                        <a className="link h3" href="https://groundcrew.com.au/">
                            Website By Groundcrew
                        </a>
                    </div>
                    <div className="w-33p ju-c flex flex-c al-i-c m-ju-c m-m-b-20 m-m-b-5">
                        <span className="h3">Where you`re going</span>
                    </div>
                    <div className="w-33p flex flex flex-r ju-e al-i-c m-ju-c ">
                        <div className="flex flex-c">
                            <button onClick={() =>{scrollTo('first')}} className="hover-white">
                            <span className="h3 m-r-10">Back to top</span><ArrowUp/>
                            </button>
                            </div>
                       
                    </div>
                </div>

            </div>
        </div>
      
    )
}

export default memo(Footer,() => true)