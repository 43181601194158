

import React, { Children, ReactNode, useEffect, useRef, useState, useCallback, useLayoutEffect } from 'react'
import useMobile from '../customHooks/useMobile/useMobile'

const LockScroll = ({ children, scrollTime = 1000, scrollTracker, loaded = true, setChildren, numChild, position, setPosition }) => {

    const scrollEl = useRef(null)
    const [time, setTime] = useState(1500)
    const [scrolled, setScrolled] = useState(false)
    const [lastPosition, setLastPosition] = useState(false)
    const isMobile = useMobile()

    useEffect(() => {
        if (isMobile) {
            setChildren(React.Children.count(children + 2))
        } else {
            setChildren(8)

        }
    }, [])


    const scrollDown = () => {

        setPosition(prev => {
            if (!isMobile) {
                if ((prev + 1) >= (numChild - 1)) {
                    return prev
                } else {
                    return prev + 1
                }
            }
            console.log('MOBIEL')
            if ((prev) >= (numChild )) {
                return prev
            } else {
                return prev + 1
            }

        })
        resetScroll()
    }

    const resetScroll = () => {

        setTimeout(() => {
            setScrolled(false)
        }, time)
    }

    const scrollUp = () => {
        setPosition(prev => {
            if (prev - 1 === -1) {
                return 0
            } else {
                return prev - 1
            }
        })
        resetScroll()

    }


    console.log(isMobile)
    const checkScroll = (e) => {
        if (e.type === 'touchmove') {
            setTime(1500)
            const currentTouch = e?.changedTouches[0]
            if (lastPosition) {
                triggerScroll(checkDirection(currentTouch.clientY - lastPosition.clientY, true))
            }
            setLastPosition(currentTouch)
        } else if (e.type === 'wheel') {
            triggerScroll(checkDirection(e.deltaY, false))
        }
    }

    const triggerScroll = (direction) => {
        console.log(direction)
        if (direction === 'down') {
            scrollDown()
        } else if (direction === 'up') {
            scrollUp()
        } else {
            setScrolled(false)
        }
    }

    const checkDirection = (swipeValue, mobile) => {

        if ((swipeValue > 0 && mobile) || (swipeValue < 0 && !mobile)) {
            return 'up'
        } else if ((swipeValue < 0 && mobile) || (swipeValue > 0 && !mobile)) {
            return 'down'
        } else {
            return 'click'
        }
    }

    useEffect(() => {
        if (scrollTracker) {
            scrollTracker(position)
        }
    }, [position])


    const controlScroll = (e) => {

        if (loaded) {
            if (!scrolled) {
                setScrolled(true)
                checkScroll(e)
            }
        }
    }

    return (
        <div ref={scrollEl} onWheel={(e) => { controlScroll(e) }} onTouchStart={(e) => { setLastPosition(e.touches[0]) }} onTouchMove={(e) => { controlScroll(e) }} onTouchEnd={() => { setLastPosition(null) }} style={{ willChange: `transform`, transform: `translateY(-${position * 100}vh)`, transition: `transform ${time}ms ease` }} className="scroll z-2">
            {children}
        </div>
    )
}

export default LockScroll