import React from "react";

const ArrowUp = () => {
    return (
        <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg" fill="#666666" stroke="#666666">
            <path d="M8.81695 4.85515L8.81695 4.83438L8.80224 4.81973L4.52602 0.559051L4.4908 0.523962L4.45551 0.558978L0.170349 4.81076L0.155566 4.82543L0.155566 4.84625L0.155566 5.85138L0.155566 5.9716L0.24082 5.88684L4.06499 2.08451L4.065 10.3789L4.065 10.4289L4.115 10.4289L4.85752 10.4289L4.90752 10.4289L4.90752 10.3789L4.90752 2.07581L8.73165 5.8868L8.81695 5.9718L8.81695 5.85138L8.81695 4.85515Z"  stroke-width="0.1" />
        </svg>
    )
}

export default ArrowUp