import React, { useEffect, useState, useRef, useCallback, useLayoutEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import LockScroll from "../components/lockScroll"
import { StaticImage } from "gatsby-plugin-image"
import Menu from "../components/menu"
import Section from "../components/section"
import TextSection from "../components/textSection"
import Footer from "../components/footer"
import { gsap, Power1 } from "gsap";
import preLoader from "../lib/preLoader"
import { mobile } from "../components/utlis/mobile"
import useMobile from "../customHooks/useMobile/useMobile"

const IndexPage = () => {

  const sections = [
    { name: 'ABOUT', about: 'North is a progressive, design-led property developer, delivering considered residences for modern living.', copy: 'True to its name, North is about always moving forward. This unwavering focal point allows the leading design firms we collaborate with the freedom to push the frontier of what residential living can be. /n A never-ending pursuit of design excellence gives inspiration to all our built form outcomes, and seeks to foster a harmony with the communities in which they reside.', maxWidth: '345px' },
    { name: 'PROJECTS', about: 'Ethereal is a timeless architectural statement that embodies our development philosophy; working with great designers on projects with outstanding site attributes. ', copy: 'We have brought together three of Australia’s premier design studios – RotheLowman, Carr Design and Form Landscape Architects – to find the ideal balance between form and function. Positioned on a hilltop and thoughtfully designed for a unique Brisbane lifestyle – the collaboration that is Ethereal is perfect for all seasons.', textClassName: 'm-p-b-40', sectionLink: { name: 'View Project', link: 'https://etherealresidences.com.au/' }, image: <StaticImage src="../images/bed.jpg" className="h-100p " />, maxWidth: '448px' },
    { name: 'MANAGING DIRECTOR', about: 'Scott Ginnivan', copy: 'North was founded in 2016 by Scott Ginnivan, a respected industry leader with over 15 years’ experience delivering mixed-use residential developments in Melbourne, Sydney and Brisbane. Throughout his career, Scott has overseen the delivery of more than 1,000 apartments with a total end value exceeding $750 million. Drawing on a proud family history in property development and a passion for design excellence and craftsmanship, Scott has a longstanding relationship with Corsair Capital and the Buxton family, one of the most respected names in Australian property.', image: <StaticImage src="../images/director.png" loading='eager' objectPosition="center center" objectFit={'contain'} className="h-100p" /> }
  ]

  const [numChild, setChildren] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [position, setPosition] = useState(0)
  const [white, setWhite] = useState('')
  const isMobile = useMobile()





  


  const scrollTo = useCallback((position) => {
    if (position === 'last') {
      if (!isMobile) {
        setPosition(numChild - 4)
      } else {
        setPosition(numChild - 2)
      }
    } else if (position === 'first') {
      setPosition(0)
    } else if (typeof position === Number) {
      setPosition(position)
    }

  }, [position])

  const first = useRef()
  const second = useRef()
  const contact = useRef()
  const logo = useRef(null)
  const image = useRef(null)
  const line = useRef(null)
  const logoMenu = useRef(null)
  const lineMenu = useRef(null)


  useEffect(() => {
    preLoader({ logo, second, line, setLoaded, image, contact, logoMenu, lineMenu })
  }, [])

  const scrollTracker = useCallback((position) => {
    console.log(position)
    if (isMobile) {
      if (position >= 2) {
        gsap.to(logo.current, { opacity: 0, duration: 0.5, ease: Power1.easeInOut })
      } else if (position < 2) {
        gsap.to(logo.current, { opacity: 1, duration: 0.5, ease: Power1.easeInOut })
      }
    }
    if (position === 1 || position === 2) {
      setWhite(true)
    } else {
      setWhite(false)
    }

  }, [position])



  return (<Layout white={white} >
    <Menu lineMenu={lineMenu} logoMenu={logoMenu} scrollTo={scrollTo} loaded={loaded} setLoaded={setLoaded} first={first} contact={contact} second={second} logo={logo} />
    <LockScroll loaded={loaded} setChildren={setChildren} numChild={(numChild - 2)} position={position} setPosition={setPosition} scrollTracker={scrollTracker}>
      <Seo title="North Property Development" />
      <div className="flex flex-c p-t-95 p-r-40 p-l-40 p-b-40 h-100vh w-100vw al-i-c re m-p-l-r-b-0" >
        <div className="w-1px flex-grow-1 back-grey ab m-a h-100p" ref={line}></div>
        <div ref={image} className="op0 h-100p re" >
          <StaticImage src="../images/heroImage.png" className="re h-100p" />
        </div>
      </div>
      {sections.map(({ name, about, copy, image, maxWidth, sectionLink, textClassName }, index) => {

        return <Section textClassName={textClassName} name={name} className={(((index % 2 === 0) ? 'flex-re' : ''))} text={<TextSection maxWidth={maxWidth} sectionName={name} sectionAbout={about} sectionCopy={copy} sectionLink={sectionLink} />} image={image} />
      })}
      <Footer scrollTo={scrollTo} />

    </LockScroll>

  </Layout>)
}

export default IndexPage
