import { gsap,Power1 } from "gsap";
import { mobile } from "../components/utlis/mobile";

const preLoader = ({logo,second,line,setLoaded,image,contact,logoMenu,lineMenu}) =>{
    console.log(logo.current)
    const tl = gsap.timeline({delay:0.5})
    tl.to(logo.current, { height: '15px', width: "48px", ease: Power1.easeInOut, duration: 1.75 })
    tl.to(second.current, { height: '95px', ease: Power1.easeInOut, duration: 1.75 }, "<")
    tl.to(line.current,{height:"100%",duration:2,ease:Power1.easeInOut},"<")
    tl.add(() =>{setLoaded(true)} )
    tl.to(image.current,{opacity:1,ease:Power1.easeInOut,duration:1},"-=0.2")
    if(!mobile()){
        console.log('mobile')
        tl.to(logo.current, { opacity: 0, ease: Power1.easeInOut, duration: 1 })
        tl.to(lineMenu.current, { opacity: 1, ease: Power1.easeInOut, duration: 1 })
        tl.to(logoMenu.current, { opacity:1, ease: Power1.easeInOut, duration: 1 })
    }
    tl.to(contact.current,{opacity:1,ease:Power1.easeInOut,duration:1},"<")
   
}

export default preLoader